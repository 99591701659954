import React from "react";
import "./Invoice.css"; // Custom CSS file for styling
import logo from './../logo.svg';
const Invoice = () => {
  return (
    <div className="container my-4 invoice-container pb-5">
      <div className="row">
        <div className="col-md-8">
          <div className="p-4 pb-0 card">
            <div className="row">
              <div className="col-md-2">
                <img src="./logo/sky.png" className="logo" />
              </div>
              <div className="col-md-7">
                <ul className="vendorDetail">
                  <li className="nameHeading">
                    <strong>Sisyphis</strong>
                  </li>
                  <li className="normalHeading">John Brandon</li>
                  <li className="normalHeading">786/1 Sector-2c, 38200 Gandhinagar, France</li>
                  <li className="normalHeading">8787989887</li>
                  <li className="normalHeading">
                    <strong>SIRET : 362 521 879 00034</strong>
                  </li>
                  <li className="normalHeading">
                    <strong>VAT: 842-484021</strong>
                  </li>
                </ul>
              </div>
              <div className="col-md-3 amountDiv">
                    <div className="billNo"> #2025-01-0001</div>
                    <div>
                    <p className="normalHeading mb-0"><strong>Total Amount</strong></p>
                    <h5>$4,500.00</h5>
                    </div>
              </div>
            </div>
            <div className="border p-3 rounded mt-3">
              <div className="row p-20 pb-0">
                <div className="col-md-4 p-3 bg-grey rounded">
                  <div className="billing mb-0">
                    <p className="normalHeading mb-0">Bill Date</p>
                    <p className="nameHeading">
                      <strong>03/05/2020</strong>
                    </p>
                  </div>
                  <div className="billing mb-0">
                    <p  className="normalHeading mb-0">Delivery Date</p>
                    <p className="nameHeading">
                      <strong>03/05/2020</strong>
                    </p>
                  </div>
                  <div className="billing mb-0">
                    <p  className="normalHeading mb-0">Terms of Payment</p>
                    <p className="nameHeading">
                      <strong>Within 15 days</strong>
                    </p>
                  </div>
                  <div className="billing mb-0">
                    <p  className="normalHeading mb-0">Payment Deadline</p>
                    <p className="nameHeading">
                      <strong>03/05/2020</strong>
                    </p>
                  </div>
                </div>
                <div className="col-md-8">
                  <p  className="normalHeading">Billing Address</p>
                  <p  className="nameHeading ">Willy wonka</p>
                  <p className="normalHeading mb-0">1445 West Norwood Avenue, Itasca, Illinois, USA</p>
                  <p className="normalHeading mb-0">97223041054 | wm@com.com</p>
                  <p className="normalHeading mb-0">
                    <strong>SIRET: 362 521 879 00034</strong>
                  </p>
                  <p className="normalHeading mb-3">
                    <strong>VAT: 842-484021</strong>
                  </p>
                  <div className="noteText">
                    <p className="normalHeading mb-0">Note</p>
                    <p className="normalHeading mb-0">
                    <strong>This is a custom message that might be relevant to the
                      customer. It can span up to three or four rows. It can
                      span up to three or four rows.</strong>
                    </p>
                  </div>
                </div>

                <table className="w-100 table p-3 mt-4">
                  <thead className="thead-light">
                    <tr>
                      <th className="text-right">No.</th>
                      <th className="text-right">Items</th>
                      <th className="text-right">Employee Name</th>
                      <th className="text-right">Hours</th>
                      <th className="text-right">Address</th>
                      <th className="text-right">Amount</th>
                    </tr>
                  </thead>
                  <tbody >
                    <tr scope="row">
                      <td align="center" >1</td>
                      <td align="left" >
                        Product Name
                        <br />
                        <small>Product Description</small>
                      </td>
                      <td align="center" >Sam Robert</td>
                      <td align="center" >20 Hours</td>
                      <td align="center" >0%</td>
                      <td className="text-right">$3,000.00</td>
                    </tr>
                    <tr>
                      <td align="center" >2</td>
                      <td align="left" >
                        Product Name
                        <br />
                        <small>Product Description</small>
                      </td>
                      <td align="center" >Jhon</td>
                      <td align="center" >10 Hours</td>
                      <td align="center" >0%</td>
                      <td className="text-right">$1,500.00</td>
                    </tr>
                  </tbody>
                </table>

                <div className="d-flex justify-content-end">
                  <div className="col-md-5">
                    <div className="d-flex justify-content-between mb-0">
                      <p className="normalHeading mb-0">Total HT</p>
                      <p className="nameHeading f-14  mb-0"><strong>$3,000.00</strong></p>
                    </div>
                    <div className="d-flex justify-content-between mb-0">
                      <p  className="normalHeading mb-0">Total Disbursements</p>
                      <p className="nameHeading f-14 mb-0"><strong>$3,000.00</strong></p>
                    </div>
                    <div className="d-flex justify-content-between mb-0">
                      <p className="normalHeading mb-0">Total VAT</p>
                      <p className="nameHeading f-14 mb-0"><strong>$3,000.00</strong></p>
                    </div>
                    <hr/>
                    <div className="d-flex justify-content-between mb-4">
                      <p className="h4 nameHeading  mb-0">Total Price</p>
                      <p className="h4 nameHeading  mb-0"><strong>$3,000.00</strong></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="invoice-footer col-md-12 py-4">
              <p  className="h4 normalHeading ">Terms &amp; Conditions:</p>
              <p  className="h4 nameHeading f-14 mb-0">
                <strong >
                  Please pay within 15 days of receiving this invoice.
                </strong>
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="  p-4 card text-center  mb-4">
            <p><strong>Invoice Not yet Sent!</strong></p>
            <button className="btn btn-success px-4">Send Invoice</button>
          </div>
          <div className="  p-4  card">
            <p className="text-left border-bottom"><strong>Summary</strong></p>
            <button className="btn btn-success px-4">Send Invoice</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoice;
